import React from 'react';
import { Link } from 'gatsby';

interface CoverProps {
  path: string
  cover: string
}

const Cover: React.FC<CoverProps> = ({ path, cover }) => {
  return (
    <Link className="thumbnail-wrapper" to={ path }>
      <div className="thumbnail-img">
        <img src={ cover } alt="" width="300" height="181" />
      </div>
    </Link>
  );
};

export default Cover;
